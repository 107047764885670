import React from "react";
import { Disclaimer } from "./Disclaimer";
import { TestimonialSlider } from "./TestimonialSlider";
import "./index.scss";

export const Feedback = () => {
  return (
    <div id="reviews" className="feedback">
      <div className="component">
        <Disclaimer />
        <TestimonialSlider />
      </div>
    </div>
  );
};
