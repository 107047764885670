import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "../../components";
import { Header } from "../../components/_templates/Header";
// import axios from "axios";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../../resources/styles/scss/main.scss";

// import { Footer } from "../../components/_templates";
import ScrollToTop from "../../components/Routes/ScrollToTop";
import { useDispatch } from "react-redux";
import { ParallaxProvider } from "react-scroll-parallax";
import "swiper/swiper-bundle.css";
import { Footer } from "components/_templates";
// import { LaptopWindowsOutlined } from "@material-ui/icons";

// Window.axios = axios;

function App() {
  const dispatch = useDispatch();
  useEffect(() => {}, [dispatch]);

  // Test ongoing, added actual pages

  return (
    <ParallaxProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Header />

        <Routes />
        <Footer />
      </BrowserRouter>
    </ParallaxProvider>
  );
}

export default App;
