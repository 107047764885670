import React from "react";

import "./MenuRibbon.scss";
import { useRef } from "react";
import Swiper from "react-id-swiper";
import SwiperCore, { Lazy, Navigation, Autoplay } from "swiper";

export const MenuRibbon = () => {
  const swiper = SwiperCore.use([Lazy, Navigation, Autoplay]);

  const swiperRef = useRef(null);

  const params_withAutoPlay = {
    ...swiper,
    spaceBetween: 0,
    margin: 0,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    loop: true,
    center: true,
    rebuildOnUpdate: true,
    slidesPerView: 1,
  };

  const data = [
    {
      text: "INFUSE YOUR VISION TO YOUR BUSINESS WITH BRANDING",
    },
    {
      text: "GUARANTEED 10% OFF ON BRANDING FOR NEW CUSTOMERS!",
    },
  ];
  return (
    <div className="MenuRibbon">
      <Swiper {...params_withAutoPlay} ref={swiperRef}>
        {data.map((e, id) => {
          return (
            <div key={id} className="menu-ribbon-slide">
              <div className="inner-wrap">
                <div className="ribbon-text">
                  <p>{e.text}</p>
                </div>
              </div>
            </div>
          );
        })}
      </Swiper>

      <div className="triangle-wrap">
        <div className="triangle"></div>
      </div>
    </div>
  );
};
