import {NAVBAR_ACTIONS} from "./actions";

export const navbar_on = () => ({
  type: NAVBAR_ACTIONS.NAVBAR_ON,
  data: true,
});

export const navbar_off = () => ({
  type: NAVBAR_ACTIONS.NAVBAR_ON,
  data: true,
});

export const kebab_on = () => ({
  type: NAVBAR_ACTIONS.KEBAB_ON,
  data: true,
});

export const kebab_off = () => ({
  type: NAVBAR_ACTIONS.KEBAB_OFF,
  data: false,
});



export const handle_navbar = (data) => {
  return (dispatch, getState) => {
    if (data) {
      dispatch(navbar_on())
    } else {
      dispatch(navbar_off())
    }
  };
};

export const handle_kebab = (data) => {
  return (dispatch, getState) => {
    data ? 
      dispatch(kebab_on()):
      dispatch(kebab_off());
  };
}