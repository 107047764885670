import React from "react";
import { useState } from "react";
import { Waypoint } from "react-waypoint";
import { handleScrollToTop } from "utils/Scrolls";
import { ArrowUp } from "../Arrow/ArrowUp";
import "./ScrollUp.scss";

const ScrollUp = () => {
  const [showArrowUp, setShowArrowUp] = useState(false);
  const onEnterScrollUp = () => {
    setTimeout(() => {
      setShowArrowUp(true);
    }, 300);
  };
  const onLeaveScrollUp = () => setShowArrowUp(false);

  return (
    <div className="scroll-up-wrap">
      <Waypoint
        onEnter={() => onEnterScrollUp()}
        onLeave={() => onLeaveScrollUp()}
      />
      {
        <div
          className={`ScrollUp ${showArrowUp ? "showScrollUp" : ""}`}
          onClick={() => handleScrollToTop()}
        >
          <ArrowUp />
        </div>
      }
    </div>
  );
};

export default ScrollUp;
