/* eslint-disable react/no-unknown-property */
import React, {
  useEffect,
  // useMemo,
  useState,
  useRef,
} from "react";
import AOS from "aos";
import "./Services.scss";
import NewWindowOpen from "utils/NewWindowOpen";
import Copywriting from "resources/images/png/avatar-a-99x99.png";
import CopywritingWebp from "resources/images/webp/avatar-a-99x99.webp";

import GraphicArtDesign from "resources/images/png/avatar-b-99x99.png";
import GraphicArtDesignWebp from "resources/images/webp/avatar-b-99x99.webp";

import OnlineStoreManagement from "resources/images/png/avatar-c-99x99.png";
import OnlineStoreManagementWebp from "resources/images/webp/avatar-c-99x99.webp";

import BizBrandingRebranding from "resources/images/png/avatar-d-99x99.png";
import BizBrandingRebrandingWebp from "resources/images/webp/avatar-d-99x99.webp";

import SocialMediaMarketing from "resources/images/png/avatar-e-99x99.png";
import SocialMediaMarketingWebp from "resources/images/webp/avatar-e-99x99.webp";

import WebDesignFrontendDevelopment from "resources/images/png/avatar-f-99x99.png";
import WebDesignFrontendDevelopmentWebp from "resources/images/webp/avatar-f-99x99.webp";

//import Tangram from "resources/images/png/tangram-design-and-print-99x99.png";
//import TangramWebp from "resources/images/webp/tangram-design-and-print-99x99.webp";

import { useSpring, animated, config } from "react-spring";

export const Services = () => {
  useEffect(() => {
    AOS.init({
      duration: 900,
    });
  }, []);
  const servicesList = [
    {
      title: "Brand Identity Development",
      content:
        "Manage your customers perception of your business by having a well established face in the market. Get noticed quickly with a well-designed brand logo, colors, typography and other elements.",
      image: OnlineStoreManagement,
      webp: OnlineStoreManagementWebp,
      imgDesc:
        "Colorful graphic icon with dotted lines, and elements showing details",
      link: "https://martleybizdesign.com/contact-us",
    },
    {
      title: "Brand & Marketing Strategy",
      content:
        "Strategic brand communications, positioning, and identity are all required to keep your business generating demand. Get everything to work in sync with our high-performing framework.",
      image: BizBrandingRebranding,
      webp: BizBrandingRebrandingWebp,
      imgDesc:
        "Man with facial hair and wearing a red shirt, holding a poster with his left thumb up",
      link: "https://martleybizdesign.com/contact-us",
    },
    {
      title: "Creative Graphic Design",
      content:
        "Expertly designed graphics can boost your offline and online marketing campaigns and draw in more of your intended audience. Obtain materials that are on-brand to capitalize on it, such as flyers, YouTube thumbnails, social media videos, and more.",
      image: GraphicArtDesign,
      webp: GraphicArtDesignWebp,
      imgDesc:
        "Man doing design studies on a desk while drinking a cup of coffee",
      link: "https://martleybizdesign.com/contact-us",
    },
    {
      title: "Copywriting",
      content:
        "Use content to spread your brand's message across multiple platforms. Advertisement copywriting, social media captions, demand content, landing page content, and everything in between.",
      image: Copywriting,
      webp: CopywritingWebp,
      imgDesc: "Man with a beard sitting at a desk while using a red laptop",
      link: "https://martleybizdesign.com/contact-us",
    },
    {
      title: "Web Design Front-end Development",
      content:
        "Provide your audiences with high-converting landing pages that will keep them talking about your brand. Enhance your business website with user-centered design and technology to improve customer experience.",
      image: OnlineStoreManagement,
      webp: OnlineStoreManagementWebp,
      imgDesc:
        "Colorful graphic icon with dotted lines, and elements showing details",
      link: "https://martleybizdesign.com/contact-us",
    },
    {
      title: "Business Process Outsourcing",
      content:
        "Our remote team takes care of your business back end, saving you time so you can concentrate on tracking your expansion. Perfect for retailers, e-commerce, and service-oriented contractors.",
      image: WebDesignFrontendDevelopment,
      webp: WebDesignFrontendDevelopmentWebp,
      imgDesc: "Sales guy smiling with a red headset",
      link: "https://martleybizdesign.com/contact-us",
    },
    {
      title: "Digital Marketing",
      content:
        "Did you know that your YouTube ad can be skipped multiple times? Instead of stopping there, let us create high-quality content. Distribute your campaign effectively by displaying it to the appropriate demographic.",
      image: SocialMediaMarketing,
      webp: SocialMediaMarketingWebp,
      imgDesc:
        "Right hand on a red computer mouse with three folder icons hovering on top",
      link: "https://martleybizdesign.com/contact-us",
    },
    {
      title: "Lead Generation",
      content:
        "Let's find qualified customers for your product or service that is carefully selected according to your specifications. Use our team's extensive experience to prospect or create landing pages for finding leads.",
      image: Copywriting,
      webp: CopywritingWebp,
      imgDesc: "Man with a beard sitting at a desk while using a red laptop",
      link: "https://martleybizdesign.com/contact-us",
    },
    {
      title: "Online Store Management",
      content:
        "Your inventory, like that of your competitors, should be depleted by now. Set monthly campaigns in line with your overall goals to boost revenue and gain consumer trust.",
      image: OnlineStoreManagement,
      webp: OnlineStoreManagementWebp,
      imgDesc:
        "Colorful graphic icon with dotted lines, and elements showing details",
      link: "https://martleybizdesign.com/contact-us",
    },
    {
      title: "App & System Product Design",
      content:
        "Prioritize the costumer experience for your business by releasing applications that enable customers to browse your goods or services while on the go. Own custom apps, websites, or systems that are future-proof.",
      image: GraphicArtDesign,
      webp: GraphicArtDesignWebp,
      imgDesc:
        "Man doing design studies on a desk while drinking a cup of coffee",
      link: "https://www.youtube.com/watch?v=Wwp8gejrWa0",
    },
    {
      title: "Special Projects",
      content:
        "We can come up with creative ideas, but we won't build you a robot overnight. Pitch to us your concept and we will realize it together.",
      image: WebDesignFrontendDevelopment,
      webp: WebDesignFrontendDevelopmentWebp,
      imgDesc: "Sales guy smiling with a red headset",
      link: "https://martleybizdesign.com/contact-us",
    },
  ];
  const [, forceUpdate] = useState();
  const [services] = useState([...servicesList]);

  const serviceRef = useRef();
  const servicesRef = useRef();

  // false = 'See More'
  // true = 'See Less'
  const [seeMoreServicesToggle, setSeeMoreServicesToggle] = useState(false);

  const servicesStyle = useSpring({
    height: seeMoreServicesToggle
      ? serviceRef?.current?.offsetHeight * servicesList.length
      : serviceRef?.current?.offsetHeight * 3,
    config: config.gentle,
  });

  useEffect(() => {
    forceUpdate();
    setSeeMoreServicesToggle(!seeMoreServicesToggle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleShowMoreOrLess = () => {
    setSeeMoreServicesToggle(!seeMoreServicesToggle);
  };

  return (
    <>
      <animated.div
        className="services"
        style={servicesStyle}
        ref={servicesRef}
      >
        {services.map((e, id) => {
          const { title, content, image } = e;
          const aosDuration = (id + 1) * 100 + 1000;

          return (
            <div
              key={id}
              className="service"
              ref={serviceRef}
              data-aos="fade-up"
              data-aos-offset="100"
              data-aos-duration={aosDuration}
            >
              <div className="icon">
                <picture>
                  <source srcSet={image.webp} type="image/webp" />
                  <img
                    src={image}
                    alt={image.title}
                    desciption={image.imgDesc}
                  />
                </picture>
              </div>
              <div className="details">
                <div className="details__texts">
                  <div className="details__texts__title">
                    <h4>{title}</h4>
                  </div>
                  <div className="details__texts__content">
                    <div className="details__texts__content__inner-wrap">
                      {content}
                    </div>
                    <div className="btn-wrap">
                      <button
                        className="btn__service"
                        onClick={() => NewWindowOpen(e.link)}
                      >
                        Get Quote
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </animated.div>

      {/* <div
        className="services-text-toggle"
        onClick={handleToggleShowMoreOrLess}
      >
        {!seeMoreServicesToggle ? "See more services" : "See less"}
      </div> */}
    </>
  );
};
