import React from "react";

import "./FormBanner.scss";

const FormBanner = () => {
  return (
    <div id="formBanner-1" className="img-wrap">
      <div id="formbanner-title-1" className="mbd-title">
        <h2 className="fw-700 text-white">
          The First Step <br /> to Your Goal
        </h2>
      </div>
    </div>
  );
};

export default FormBanner;
