import React from "react";
import "./index.scss";
import { Menu } from "components/_templates";
import * as router from "react-router-dom";

export const Header = () => {
  const history = router.useHistory();
  const loc = history.location;

  // console.log(`loc`, loc);

  return (
    <>
      {
        // !(loc.pathname === '/') &&
        <div
          className="header"
          // style={{"display":"none"}}
        >
          <Menu />
        </div>
      }
    </>
  );
};
