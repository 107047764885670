import React from 'react';
import './index.scss';

export const TitleGeneric = (props) => {
    const {segment1, segment2, alignment, weight} = props;
    
    const style = {
        fontWeight: weight,
        textAlign: alignment
    }

    return (
        <div className="title-generic">
            <h2 style={{...style}}>
                <span className="segment1">{segment1}</span>
                <span className="segment2">{segment2}</span>
            </h2>
        </div>
    )
}
