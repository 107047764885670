import React from "react";
import { Bannered } from "../Templates/Bannered/Bannered";
import SubsciptionForm from "./SubsciptionForm";

const Subscribe = (props) => {
  const { children } = props;

  return (
    <>
      <Bannered>
        <SubsciptionForm>{children}</SubsciptionForm>
      </Bannered>
    </>
  );
};

export default Subscribe;
