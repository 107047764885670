import React from "react";
import "./index.scss";
// import { getFaqsPageSet } from "store/actionCreators/faqPage/index";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
// import { useStateSelector } from "./hooks";

const AboutUs = () => {
  // const { pageTitleSub } = useStateSelector();

  // const pageBannerSub = pageTitleSub;
  const aboutUri = useSelector((response) => response.appEndpoints);

  // console.log("aboutUri", aboutUri);

  const history = useHistory();
  // console.log(`useHistor`, history);

  const location = useLocation();
  // console.log(`useLocation`, location);

  return <div className="aboutpage page">about</div>;
};

export default AboutUs;
