import { BlackRedTitle, Red } from "components/_ui/BlackRedTitle";
import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import "./Success.scss";

const Success = () => {
  const history = useHistory();

  const goToPath = (route) => {
    history.push(`${route}`);
  };

  useEffect(() => {
    setTimeout(() => {
      setTimeout(() => {
        history.push("/");
      }, 10000);
    });
  }, []);

  return (
    <>
      <div
        id="contact-us-success-message"
        className="col-12 d-flex align-items-center justify-content-center flex-column"
      >
        <BlackRedTitle tag="h1" classNames={"fw-700"}>
          <Red>Perfect!</Red>
        </BlackRedTitle>

        <div className="mbd-title">
          <h2 className="sub-head text-center fw-500">
            Your message has been sent. <br /> Kindly wait for our reply.
          </h2>
          <h3 className="text-center fw-500">
            Thank you, keep making progress
          </h3>
        </div>

        <button
          onClick={() => goToPath("/")}
          className="btn btn-test btn-mbd-block-sm btn-mbd-secondary"
        >
          Close
        </button>
      </div>
    </>
  );
};

export default Success;
