/* eslint-disable react/no-unknown-property */
import React, { useEffect } from "react";
import "./BizNeeds.scss";
import BizNeeds__featured from "resources/images/png/featured-project.png";
import BizNeeds__featuredWebp from "resources/images/webp/featured-project.webp";
import AOS from "aos";

const BizNeeds = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
      mirror: true,
    });
  }, []);

  return (
    <div id="featured" className="bizNeeds">
      <div className="component">
        <div
          className="inner-wrap"
          data-aos="zoom-out-right"
          data-aos-offset="-200"
          data-aos-duration="700"
        >
          <div className="bizNeeds__img-wrap">
            <picture>
              <source srcSet={BizNeeds__featuredWebp} type="image/webp" />
              <img
                src={BizNeeds__featured}
                alt="martley business design featured project"
                description="a fresh website mockup presented on an iphone, with red and mustard bubble chats hovering"
              />
            </picture>
          </div>

          <div
            className="bizNeeds__contents"
            data-aos="fade-up"
            data-aos-offset="-200"
            data-aos-delay="200"
            data-aos-duration="700"
          >
            <div className="title">
              <h2>
                App Built for
                <br />
                <span>Real People</span>
              </h2>
            </div>
            <div className="description">
              <h3>
                Enhance customer experiences with a custom website and benefit
                from flexible payment options.
                <br />
                <br />
                <span>&#35;websolutions &#35;businesswebsites</span>
              </h3>
            </div>
            <div className="btn-wrap">
              <button
                onClick={() =>
                  window.open("https://www.youtube.com/watch?v=Wwp8gejrWa0")
                }
                className="btn btn-danger"
              >
                Watch now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BizNeeds;
