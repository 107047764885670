import { useSelector } from "react-redux";
import {handle_kebab} from "store/actionCreators/Navbar";
import {useDispatch} from "react-redux";

export const useStateSelector = () => ({
    kebab: useSelector((state) => state.navbar.kebab),
});

export const useActionDispatch = () => {
  const dispatch = useDispatch();

  return {
    handle_kebab: (data) => dispatch(handle_kebab(data))
  };
};
