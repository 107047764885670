/* eslint-disable react/no-unknown-property */
import React, { useRef, useEffect } from "react";
import Swiper from "react-id-swiper";
import { ArrowRight } from "components/_ui/Arrow/ArrowRight";
import { ArrowLeft } from "components/_ui/Arrow/ArrowLeft";
import StarIcon from "@material-ui/icons/Star";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import DANNYALVAREZ from "resources/images/jpeg/danny-chino-alvarez-135x135.jpg";

import BELLAHARDINGE from "resources/images/jpeg/bella-hardinge-135x135.jpg";
import BELLAHARDINGEWebp from "resources/images/webp/bella-hardinge-135x135.webp";

import EDUARDOALMODOVAR from "resources/images/jpeg/eduardo-almodovar-135x135.jpg";
import EDUARDOALMODOVARPng from "resources/images/png/eduardo-almodovar-135x135.png";

import FEMALE from "resources/images/jpeg/female-avatar-135x135.jpg";
import FEMALEWebp from "resources/images/webp/female-avatar-135x135.webp";

import KELVINTIRADO from "resources/images/jpeg/kelvin-tirado-135x135.jpg";
import KELVINTIRADOWebp from "resources/images/webp/kelvin-tirado-135x135.webp";

import MALE from "resources/images/jpeg/male-avatar-135x135.jpg";
import MALEWebp from "resources/images/webp/male-avatar-135x135.webp";

import MARKSICOLA from "resources/images/jpeg/mark-sicola-135x135.jpg";
import MARKSICOLAWebp from "resources/images/webp/mark-sicola-135x135.webp";

import "./testimonialSlider.scss";

import SwiperCore, { Lazy, Navigation, Autoplay } from "swiper";

import AOS from "aos";

export const TestimonialSlider = () => {
  useEffect(() => {
    AOS.init({
      duration: 900,
      mirror: true,
    });
  }, []);

  // Modal Settings
  const data = [
    {
      img: EDUARDOALMODOVAR,
      name: "Eduardo Almodovar",
      position: "Real Estate Photographer",
      businessName: "Focuzz™, Puerto Rico",
      message:
        "100% Recommended. Very professional, Good communication, and Excellent Quality in their work.",
      longdesc:
        "Tattooed man with his reflection on a car side mirror while shooting with a camera",
      png: EDUARDOALMODOVARPng,
    },
    {
      img: BELLAHARDINGE,
      name: "Bella Hardinge",
      position: "Head of Operations",
      businessName: "London, UK",
      message:
        "Really Professional, and a Super Quick Turnaround. Lots of Exciting, and On-Brand Ideas - Thank you!",
      longdesc: "Couple in black and white smiling posing for the camera",
      webp: BELLAHARDINGEWebp,
    },
    {
      img: DANNYALVAREZ,
      name: "Danny Alvarez",
      position: "Business Owner/Pro Surfer",
      businessName: "DieselTech Services, LLC / DieselJunkie™",
      message:
        "Professionalism, Quality, Efficiency, Skill, Attention to Detail, and most importantly Communication.",
      longdesc:
        "Surfer in yellow jersey looking left while holding a red drinking cup in a bright sunny day at the beach",
    },
    {
      img: MARKSICOLA,
      name: "Mark Sicola",
      position: "Entrepreneur/Content Creator",
      businessName: "The Mark List, California",
      message:
        "He took the time to look at what I wanted, and came up with a Great concept.",
      longdesc: "Four ladies with a man in a social gathering, smiling",
      webp: MARKSICOLAWebp,
    },
    /*{
      img: KELVINTIRADO,
      name: "Kelvin Tirado Blas",
      position: "Businessman/Diesel Technician",
      businessName: "PM Generator Services, Puerto Rico",
      message:
        "Excellent Business Designer!! 5 stars!. Great work and very professional.",
      longdesc: "Muscular man jogging by the beach in black shirt and shade",
      webp: KELVINTIRADOWebp,
    },*/
    {
      img: FEMALE,
      name: "Olivia S.",
      position: "Entrepreneur",
      businessName: "Start-Up, France",
      message:
        "Putting up a Start-Up becomes more easier with this guy!, learned new tricks everyday!.",
      longdesc: "Middle aged female icon with long hair",
      webp: FEMALEWebp,
    },
    {
      img: MALE,
      name: "Jessie A.",
      position: "Business Owner/Rapper",
      businessName: "Toronto, Canada",
      message:
        "When I sent a request, just took an hour to get quotation despite of our time differences. Great outputs!",
      longdesc: "Middle aged male icon with a necktie",
      webp: MALEWebp,
    },
    {
      img: MALE,
      name: "Alexander G.",
      position: "Business Owner",
      businessName: "ProPower, USA",
      message: "Great work, was very responsive and worked hard to please.",
      longdesc: "Middle aged male icon with a necktie",
      webp: MALEWebp,
    },
  ];

  const swiper = SwiperCore.use([Lazy, Navigation, Autoplay]);

  const params_withAutoPlay = {
    ...swiper,
    spaceBetween: 0,
    margin: 0,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    loop: true,
    center: true,
    rebuildOnUpdate: true,
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
    },
  };

  const swiperRef = useRef(null);

  const goNext = () => {
    // console.log(`next`);
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  // const renderTooltip = (props) => (
  //   <Tooltip id="button-tooltip" {...props}>
  //     Simple tooltip
  //   </Tooltip>
  // );

  const manipulateString = (feedBack) => {
    const countChars = feedBack.message.length;
    if (countChars <= 90) {
      return feedBack.message;
    } else {
      return (
        <>
          {feedBack.message.substring(0, 95)}... <br />
        </>
      );
    }
  };

  return (
    <div className="testimonial_slider">
      <div className="arrow-wrap left" onClick={goPrev}>
        <ArrowLeft className="arrow" />
      </div>

      <div
        className="innerSlider"
        data-aos="fade-right"
        data-aos-offset="-200"
        data-aos-delay="200"
        data-aos-duration="700"
      >
        <div className="slider">
          <Swiper {...params_withAutoPlay} ref={swiperRef}>
            {data.map((e, id) => {
              return (
                <div key={id} className="slide">
                  <div className="inner-wrap">
                    <div className="img-wrap">
                      <picture>
                        {e?.png && <source srcSet={e.png} type="image/webp" />}
                        {e?.webp && (
                          <source srcSet={e.webp} type="image/webp" />
                        )}
                        <img
                          src={e.img}
                          alt={e?.name}
                          description={e?.longdesc}
                        />
                      </picture>
                    </div>
                    <div className="business-details">
                      <p className="profile">{e.name}</p>
                      <p className="position">{e.position}</p>
                      <p className="business">{e.businessName}</p>
                    </div>
                    <div className="message-details">
                      {/* <p className="message">{manipulateString(e)}</p> */}

                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip">{e.message}</Tooltip>
                        }
                      >
                        <p className="message">{manipulateString(e)}</p>
                      </OverlayTrigger>
                    </div>
                    <div className="stars">
                      <StarIcon />
                      <StarIcon />
                      <StarIcon />
                      <StarIcon />
                      <StarIcon />
                    </div>
                  </div>
                </div>
              );
            })}
          </Swiper>
        </div>
      </div>

      <div className="arrow-wrap right" onClick={goNext}>
        <ArrowRight className="arrow" />
      </div>
    </div>
  );
};
