import {NAVBAR_ACTIONS} from "store/actionCreators/Navbar/actions";

const initialState = {
  show: true,
  kebab: false,
};

export const navbar = (state = initialState, action) => {
  switch (action.type) {

    case NAVBAR_ACTIONS.NAVBAR_ON:
      return {
        ...state,
        show: action.data
      };

    case NAVBAR_ACTIONS.NAVBAR_OFF:
      return {
        ...state,
        show: action.data
      };

    
      // KEBAB CONTROLS
    case NAVBAR_ACTIONS.KEBAB_ON:
      return {
        ...state,
        kebab: action.data
      };
    
    case NAVBAR_ACTIONS.KEBAB_OFF:
      return {
        ...state,
        kebab: action.data
      }

    default:
      return state;
  }
};
