import * as yup from "yup";

export const basicSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Please enter your email address")
    .required("Email is required"),
  message: yup
    .string()
    .min(10, "Minimum of 10 characters is required")
    .required("Please enter your message"),
});

export const subsciptionSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Please enter your email address")
    .required("Email is required"),
});
