import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route, useLocation } from "react-router-dom";

const RouteMiddleware = ({ path, component: Component, route, ...rest }) => {
  const location = useLocation();

  const updateMetaTag = () => {
    document.title = !!route.title && route.title;
    route?.meta.map((currentRoute) =>
      document
        .querySelector(`meta[name=${currentRoute.name}]`)
        .setAttribute("content", `${currentRoute.content}`)
    );
  };

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search,
      page_url: window.location.href,
    });
    updateMetaTag();
  }, [location]);

  return (
    <Route {...rest} path={path} render={(props) => <Component {...props} />} />
  );
};

export default RouteMiddleware;
