import React from "react";
import "./BlackRedTitle.scss";
import parser from "html-react-parser";

const BlackRedTitle = ({ tag = "h1", classNames, children }) => {
  const renderWithTag = (titleTag, classNames, child) => {
    if (titleTag === "h1") {
      return <h1 className={classNames}>{child}</h1>;
    }
  };
  return (
    <div className="mbd-title">
      {!!children && renderWithTag(tag, classNames, children)}
    </div>
  );
};

export default BlackRedTitle;

//switch case in javascript?
