import React from "react";
import "./Spinner.scss";

const Spinner = () => {
  return (
    <div
      id="mbd-spinner"
      className="spinner w-100 d-flex flex-column align-items-center justify-content-center  flex-grow-1 rounded"
    >
      <div className="spinner-inner-wrap">
        <div className="spinner-border text-mbd-primary" role="status"></div>
      </div>
    </div>
  );
};

export default Spinner;
