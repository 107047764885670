export const EMAILJS = {
  service_id: "service_8tk4ytq",
  template_id: "template_f68th0u",
  user_id: "_URx1mbV9Yvl736qo",
  accessToken: "jrQNTLrHZkD-VuYwW6tj7",
};

export const SENDINBLUE = {
  api_key:
    "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",
};
