import React from "react";
import DisordSVG from "./icons/discord.svg";
import "./Discord.scss";
const DiscordBtn = () => {
  return (
    <a
      type="button"
      // href="https://discord.com/invite/nvTBbyfSbz"
      href="https://martleybizdesign.com/contact-us"
      className="discordbtn"
      target="_blank"
      rel="noreferrer"
    >
      {/* <div> 
        <img src={DisordSVG} alt="" />
      </div>*/}
      Claim your discount now
    </a>
  );
};

export default DiscordBtn;
