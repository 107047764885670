import React from "react";
import "./contact.scss";
// import { getFaqsPageSet } from "store/actionCreators/faqPage/index";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Bannered } from "../Templates/Bannered/Bannered";
import ContactForm from "./ContactForm";
// import { useStateSelector } from "./hooks";

const Contact = (props) => {
  const { children } = props;
  return (
    <>
      <Bannered>
        <ContactForm>{children}</ContactForm>
      </Bannered>
    </>
  );
};

export default Contact;
