import React from "react";
import DefaultBanner from "resources/images/png/gray-banner.jpg";
import "./Banner.scss";
const Banner = (props) => {
  const { image } = props;

  return (
    <div
      className="gray-banner"
      style={{ backgroundImage: `url(${!!image ? image : DefaultBanner})` }}
    ></div>
  );
};

export default Banner;
