import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from "./reducers/rootReducer";

export const configuredStore = () => {
  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(
    thunk
  )));
  return store;
};
