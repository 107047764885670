import { Black, BlackRedTitle, Red } from "components/_ui/BlackRedTitle";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useRef } from "react";
import { Spinner } from "components/_ui";
import { subsciptionSchema } from "schemas";
import FormBanner from "../Contact/FormBanner";
import axios from "axios";
import "./SubscriptionForm.scss";
import { SENDINBLUE } from "config/constants";
import { useHistory } from "react-router";

const SubsciptionForm = (props) => {
  const { children } = props;
  const history = useHistory();
  const form = useRef();
  const [showSuccessbanner, setShowSuccessbanner] = useState(false);

  const onSubmit = async (values, actions) => {
    values = {
      ...values,
      listIds: [6],
    };

    await axios
      .post("https://api.sendinblue.com/v3/contacts", values, {
        headers: {
          "Content-Type": "application/json",
          "api-key": SENDINBLUE.api_key,
        },
      })
      .then((response) => {
        actions.resetForm();
        setShowSuccessbanner(true);
        history.push("/subscribed");
      })
      .catch((error) => {
        actions.resetForm();
      });
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: "",
      name: "",
    },
    validationSchema: subsciptionSchema,
    onSubmit,
  });

  const showForm = () => {
    return (
      <form
        id="contact"
        ref={form}
        className="needs-validation"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <div className="d-flex flex-column input-group">
          <label htmlFor="name">What&apos;s your name?</label>
          <input
            id="name"
            type="text"
            value={values.name}
            placeholder="Firstname Lastname"
            onChange={handleChange}
            onBlur={handleBlur}
            className={`form-control w-100 rounded ${
              errors.name && touched.name && "input-error"
            }`}
          />
          {errors.name && touched.name && (
            <p className="error-message">{errors.name}</p>
          )}
        </div>
        <div className="d-flex flex-column input-group">
          <label htmlFor="email">Your email?</label>
          <input
            id="email"
            type="email"
            value={values.email}
            placeholder="username@email.com"
            onChange={handleChange}
            onBlur={handleBlur}
            className={`form-control w-100 rounded  ${
              errors.email && touched.email ? "input-error" : ""
            }`}
          />
          {errors.email && touched.email && (
            <p className="error-message">{errors.email}</p>
          )}
        </div>

        <button
          id="mbd-submit-contact-form"
          disabled={isSubmitting}
          type="submit"
          className="btn btn-mbd-primary float-right"
        >
          {" "}
          Subscribe
        </button>
      </form>
    );
  };

  const subscribe = () => {
    return (
      <>
        {isSubmitting ? (
          <Spinner />
        ) : (
          <>
            <FormBanner />
            <div
              id="contact-us-form-wrap"
              className="form-wrap d-flex flex-column justify-content-start"
            >
              <BlackRedTitle tag="h1" classNames={"fw-700"}>
                <Red>Let&apos;s keep,</Red>
                <br />
                <Black>in touch</Black>
              </BlackRedTitle>
              {showForm()}
              <p className="mt-4 fw-500">
                By providing your contact details and submitting this form you
                are consenting to receive marketing emails from Martley™
                Business Design. You can revoke your consent to receive emails
                at any time by using this unsubscribe link found at the bottom
                of every email.
              </p>
            </div>
          </>
        )}
      </>
    );
  };

  const redirection = () => {
    return (
      <>
        <div className="w-100 d-flex align-items-center justify-content-center">
          <p className="fw-500">Redirecting...</p>
        </div>
      </>
    );
  };

  return (
    <div id="SubscriptionForm" className="component">
      <div className="inner-wrap">
        {children
          ? children
          : !!!showSuccessbanner
          ? subscribe()
          : redirection()}
      </div>
    </div>
  );
};

export default SubsciptionForm;
