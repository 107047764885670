import Banner from "components/_ui/Banner/Banner";
import React from "react";
import "./Bannered.scss";

export const Bannered = (props) => {
  const { banner, children } = props;
  return (
    <div className="page bannered">
      <Banner image="" />
      {children}
    </div>
  );
};
