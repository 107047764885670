import React from "react";
import {
  CoreServices,
  Feedback,
  MartleyNutshell,
  MBDClassic,
  NewsOffers,
  //Subscription,
  //Vouchers,
  MartleyNutshell2,
} from "components/_ui";
import { BizNeeds } from "components/_ui";
// import React,{lazy, Suspense} from "react";
import { Hero } from "../../_templates";
import "./index.scss";
import { useEffect } from "react";
// import { Footer } from "components/_templates";

// import { useStateSelector, useActionDispatch  } from "./hooks";
// import {useHistory,useParams} from 'react-router-dom';

// test

const string =
  " |title-primary: Lorem ipsum dolor sit amet| consectetur adipisicing elit. Explicabo atque consequatur magni vero fugiat, reiciendis doloremque quidem, veniam magnam obcaecati maxime minus molestias ea provident beatae eaque consequuntur doloribus tenetur.";

const Homepage = () => {
  return (
    <div className="page home">
      <Hero />
      {/* <Vouchers /> */}
      {/* <Subscription /> */}
      <CoreServices />
      <Feedback />
      <MBDClassic />
      <BizNeeds />
      <NewsOffers />
      <MartleyNutshell />
      <MartleyNutshell2 />
    </div>
  );
};

export default Homepage;
