import React, { useState, useEffect } from "react";
import "./Menu.scss";
import mbd from "../../../resources/images/svg/martley_full_logo.svg";
import mbdSymbol from "resources/images/svg/martley_symbol.svg";
import { useStateSelector, useActionDispatch } from "./hooks.js";
import { Kebab } from "components/_templates";
import { HashLink as Link } from "react-router-hash-link";
import { MenuRibbon } from "components/_ui";
import { useSpring, animated, config } from "react-spring";
import { Waypoint } from "react-waypoint";
import { useHistory } from "react-router";

const Menu = () => {
  const history = useHistory();
  const path = history.location.pathname;

  const [scrollPosition, setScrollPosition] = useState(true);
  // const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    // console.log(`scrollPosition`, scrollPosition);
  }, [scrollPosition]);

  const menuTransition = useSpring({
    // marginBottom: isDesktop ? ( scrollPosition ? '1.5em':'-.5em' ):('0'),
    config: config.gentle,
  });

  const { kebab } = useStateSelector();
  const { handle_kebab } = useActionDispatch();

  const handleClickMenu = () => {
    !kebab ? handle_kebab(true) : handle_kebab(false);
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const handleScroll = (el) => {
    // console.log(`el`);
  };

  const Enter_MenuRibbon = () => {
    setScrollPosition(true);
  };

  const Leave_MenuRibbon = (event) => {
    setScrollPosition(false);
    // console.log(`leave`)
  };

  const showLogo = () => {
    if (path !== "/") {
      return (
        <a href="/">
          <img
            src={mbdSymbol}
            className="logo-symbol"
            alt="martley business design"
          />
        </a>
      );
    }

    if (scrollPosition) {
      return (
        <a href="/">
          <img src={mbd} alt="martley business design" />
        </a>
      );
    } else {
      return (
        <a href="/">
          <img
            src={mbdSymbol}
            className="logo-symbol"
            alt="martley business design"
          />
        </a>
      );
    }
  };

  return (
    <>
      <Waypoint onEnter={Enter_MenuRibbon} onLeave={Leave_MenuRibbon} />

      <div
        className={`menu-bar ${path === "/" ? "full" : "static"}`}
        onScroll={handleScroll}
      >
        <animated.div
          style={menuTransition}
          className={`ribbon ${scrollPosition ? "tall" : "short"}`}
        >
          {path === "/" && <MenuRibbon />}
        </animated.div>
        <div className={`component ${scrollPosition ? "tall" : "short"}`}>
          <div className="inner-wrap">
            {/* logo */}
            <div className="logo">{showLogo()}</div>

            {/* menu */}
            <div className="menu">
              {path === "/" && ( //show||hide kebab word link when navigating inside
                <div className="trigger">
                  <Link
                    scroll={scrollWithOffset}
                    smooth={(el) =>
                      el.scrollIntoView({
                        behavior: "auto",
                        block: "end",
                        offset: 100,
                      })
                    }
                    to="#about"
                  >
                    About
                  </Link>
                </div>
              )}

              <div className="burger" onClick={handleClickMenu}>
                <div className="dot top"></div>
                <div className="dot mid"></div>
                <div className="dot bottom"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Kebab />
    </>
  );
};

export default Menu;
