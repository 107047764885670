import React from "react";

export const ArrowUp = () => {
  return (
    <div className="ArrowUp_solid-white">
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1000 571.77"
      >
        <path
          d="M943.54,571.74A56.29,56.29,0,0,1,900.65,552L550.72,143.94c-12.12-14.14-30.79-31-50.86-31s-38.68,16.88-50.81,31L99.38,552a56.48,56.48,0,0,1-85.79-73.49l349.68-408C413.18,12.25,465.09.05,499.84,0h.05c34.75,0,86.66,12.25,136.57,70.4L986.39,478.53a56.45,56.45,0,0,1-42.85,93.21Z"
          style={{ fill: "#fff" }}
        />
      </svg>
    </div>
  );
};
