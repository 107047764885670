import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { ROUTES_CONFIG } from "./config";
import RouteMiddleware from "./RouteMiddleware";

export const Routes = () => {
  return (
    <Suspense fallback={<p></p>}>
      <Switch>
        {ROUTES_CONFIG.map((route, i) => {
          return (
            <RouteMiddleware
              key={i}
              exact={!!route.exact}
              path={route.path}
              route={route}
              component={() => route.component}
            />
          );
        })}
        <Route component={() => <div>404</div>} />
      </Switch>
    </Suspense>
  );
};
